<template>
  <div>
    <p>安全设置</p>
    <div style="margin-left: 100px">
      <div class="PWD">
        <div class="password">
          <p>账号密码</p>
          <p>当前密码强度:{{ degree }}</p>
        </div>
        <span style="color: #1890ff; cursor: pointer" @click="amendPwd"
          >修改</span
        >
      </div>
      <div class="Mobile">
        <div class="Tel">
          <p>密保手机</p>
          <p>已绑定手机:{{ Mobile }}</p>
        </div>
        <span style="color: #1890ff; cursor: pointer" @click="amendTel"
          >修改</span
        >
      </div>
    </div>
    <el-dialog title="修改密码" :visible.sync="ChangePwd" top="30px">
      <span
        >修改密码需要向手机
        <p style="color: #1890ff; display: inline">{{ Mobile }}</p>
        发送验证码</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="ChangePwd = false">取 消</el-button>
        <el-button type="primary" @click="affirm_Pwd">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="修改密码" :visible.sync="Changeagain" top="30px">
      <el-form
        :model="NewPassword"
        ref="articleForm"
        style="margin-left: 100px"
      >
        <el-form-item label="请输入新密码:">
          <el-input
            v-model="NewPassword.Pwd"
            type="password"
            style="width: 600px; float: left"
          ></el-input>
        </el-form-item>
        <el-form-item label="请输入验证码:">
          <el-input
            v-model="NewPassword.verification"
            style="width: 500px; float: left"
          ></el-input>
          <span class="sendyzm" @click="sendYZM" v-show="show">发送验证码</span>
          <span class="sendyzm" v-show="!show" style="color: #cfcfcf">
            {{ count + "秒" + "后重发"}}
          </span>
        </el-form-item>
        <el-form-item>
          <el-button @click="Changeagain = false">取 消</el-button>
          <el-button type="primary" @click="affirm_ok">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

     <el-dialog title="修改手机号" :visible.sync="ChangeMoblie" top="30px">
         <el-form :model="NewTels" ref="articleForm" style="margin-left: 100px">
           <el-form-item label="请输入当前手机号:">
          <el-input
            v-model="NewTels.activeTel"
            style="width: 600px; float: left"
          ></el-input>
        </el-form-item>
         <el-form-item label="请输入新手机号:">
          <el-input
            v-model="NewTels.moblies"
            style="width: 600px; float: left;margin-left:15px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="ChangeMoblie = false">取 消</el-button>
          <el-button type="primary" @click="Tel_affirm">下一步</el-button>
        </el-form-item>
         </el-form>
    </el-dialog>

    <el-dialog title="短信验证" :visible.sync="Sms" top="30px">
        <el-form :model="Note" ref="articleForm" style="margin-left: 100px">
           <el-form-item label="" style="margin-right:80px">
             <span>验证码已发送至<p style="color: #1890ff;display:inline">{{NewTels.moblies}}</p></span>
          </el-form-item>
           <el-form-item label="请输入验证码:">
          <el-input
            v-model="Note.verification"
            style="width: 500px; float: left"
          ></el-input>
          <span class="sendTelyzm" @click="sendTelYZM" v-show="Telshow">发送验证码</span>
          <span class="sendTelyzm" v-show="!Telshow" style="color: #cfcfcf">
            {{ count + "秒" + "后重发"}}
          </span>
        </el-form-item>
            <el-form-item>
              <el-button @click="Sms = false">取 消</el-button>
              <el-button type="primary" @click="verify_Tel">验证</el-button>
        </el-form-item>
        </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { setPass, setPassSms, sendSms, setTel } from '@/api/TeacherApi.js'
export default {
  data () {
    return {
      degree: '强',
      ChangePwd: false, // 修改密码提示弹窗
      Changeagain: false, // 修改密码
      ChangeMoblie: false, // 修改手机弹窗
      Sms: false, // 短信验证
      //   Tel: "15500210032", //手机号
      Mobile: '138****8293',
      regex: /^1[3456789]\d{9}$/, // 手机号正则
      show: false,
      Telshow: false,
      timer: null, // 验证码标识
      count: '',
      ActiveTel: '', // 当前手机号
      NewPassword: {
        Pwd: '', // 新密码
        verification: '' // 验证码
      },
      NewTels: {
        activeTel: '', // 当前手机号
        moblies: '' // 新手机号
      },
      Note: {
        verification: '' // 修改手机的验证码
      }
    }
  },
  mounted () {
    const Mobiles = JSON.parse(localStorage.getItem('userInfo'))
    var NewMoblie = Mobiles.tel
    this.ActiveTel = NewMoblie
    NewMoblie += ''
    const Tel = NewMoblie.replace(NewMoblie.substring(3, 7), '****')
    this.Mobile = Tel
  },
  methods: {
    amendPwd () {
      // 修改密码
      this.ChangePwd = true // 打开弹窗
    },
    amendTel () {
      // 修改手机号
      this.ChangeMoblie = true
    },
    Tel_affirm () { // 修改手机号弹窗,下一步
      if (this.NewTels.activeTel != this.ActiveTel) {
        this.$message('当前手机号输入有误')
      } else if (!this.regex.test(this.NewTels.moblies)) {
        this.$message('新手机号输入有误')
      } else {
        const data = {
          phone: this.NewTels.moblies,
          type: 1
        }
        sendSms(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '发送成功',
              type: 'success'
            })
            this.Sms = true
            this.YZMsend()
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => { console.log(err) })
      }
    },
    sendTelYZM () { // 修改手机号重发
      const data = {
        phone: this.NewTels.moblies,
        type: 1
      }
      sendSms(data).then(res => {
        if (res.status.code == 1) {
          this.YZMsend()
          this.$message({
            message: '发送成功',
            type: 'success'
          })
        } else {
          this.$message.error(res.status.msg)
        }
      }).catch(err => { console.log(err) })
    },
    verify_Tel () { // 点击验证
      const data = {
        phone: this.NewTels.moblies, // 新手机号
        code: this.Note.verification // 验证码
      }
      setTel(data).then(res => {
        if (res.status.code == 1) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.ChangeMoblie = false
          this.Sms = false
          this.NewTels = {} // 清空
          this.Note = {} // 清空
        } else if (this.Note.verification == '') {
          this.$message('验证码不能为空')
        } else {
          this.$message.error(res.status.msg)
        }
      }).catch(err => { console.log(err) })
    },
    affirm_Pwd () {
      // 修改密码提示弹窗的确定
      setPassSms().then(res => {
        // console.log(res)
        if (res.status.code == 1) {
          this.Changeagain = true
          this.YZMsend()
        } else {
          this.$message.error(res.status.msg)
        }
      }).catch(err => { console.log(err) })
      // console.log(8989);
    },
    affirm_ok () {
      // 修改密码二次确定
      const data = {
        code: this.NewPassword.verification,
        pass: this.NewPassword.Pwd
      }
      if (this.NewPassword.Pwd == '') {
        this.$message('密码不能为空')
      } else if (this.NewPassword.verification == '') {
        this.$message('验证码不能为空')
      } else {
        setPass(data).then(res => {
          // console.log(res)
          if (res.status.code == 1) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.NewPassword = {}
            this.Changeagain = false
            this.ChangePwd = false
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => { console.log(res) })
      }
    },
    sendYZM () {
      // 点击发送验证码
      setPassSms().then(res => {
        // console.log(res)
        if (res.status.code == 1) {
          this.YZMsend()
        } else {
          this.$message.error(res.status.msg)
        }
      }).catch(err => { console.log(err) })
    },
    YZMsend () {
      // 验证码
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        this.Telshow = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            this.Telshow = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.PWD {
  display: flex;
  width: 884px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  .password {
    // text-align: left;
    p:nth-child(1) {
      // align-self: left;
      text-align: left;

      color: cadetblue;
    }
  }
  span {
    // align-self: flex-end;
  }
}
.Mobile {
  display: flex;
  width: 884px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  .Tel {
    p:nth-child(1) {
      text-align: left;
      color: cadetblue;
    }
  }
}
.sendyzm{
    width: 100px;
    height: 40px;
    float: left;
    cursor: pointer;
    display: inline-block;
    border: 1px solid #cccccc;
}
.sendTelyzm{
   width: 100px;
    height: 40px;
    float: left;
    cursor: pointer;
    display: inline-block;
    border: 1px solid #cccccc;
}
</style>
